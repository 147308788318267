<template lang="pug">
EatUl
  EatLi.d-flex.flex-column(v-if="cart" v-for="item, i in cart.items" :key="i" :class="{ 'mb-5': i === cart.items.length - 1 }")
    div.card(@click="openDetailProductDialog(item, i)")
      EatImage.card__image.rounded-sm(
        :src="getImageSrc(item.product)"
        :lazyLoading="true"
      )
      div.px-3.text-h6 {{ item.product.name }}
      div.card__controls
        div.d-flex.align-center
          EatIconBtn(icon="$trash" v-if="!item.quantity" @click.stop="deleteItem(item)")
          EatStepper(small @increase="changeQuantity(item, +1)" :value="item.quantity" @decrease="changeQuantity(item, -1)")
        div.mt-4.text-body-1.font-weight-regular
          ItemPrice(v-if="item.variation"
            :isPercentage="item.variation.isPercentage"
            :variation="item.variation.variation", 
            :price="item.variation.price" 
            :finalPrice="item.variation.finalPrice"
          )
          Price(v-else :price="computePrice(item.product, item.configuration) * item.quantity")

    div(@click="openDetailProductDialog(item, i)")
      ItemConfigurationSummary(:item="item")
    
FullPageDialog(v-model="showDialog")
  template(v-slot:header)
    div.d-flex.flex-column(style="width: 100%")
      div.d-flex.align-center.pa-2.bg-white
        EatIconBtn.text-black.me-4.ms-3(icon="$close" :size="16" @click="onCloseDialog")
        div.product-name.text-body-1.font-weight-medium.text-truncate.pe-6(v-if="currentItem && currentItem.product") {{ currentItem.product.name }}
  template(v-slot:main)
    div.product-builder__container.bg-neutral-a.flex-grow-1(v-if="currentItem")
      div.rounded-b-xxl.bg-white.py-3.px-5
        EatImage.product-builder__image.rounded(
          :src="getImageSrc(currentItem.product)"
        )
        div.card-opened__text.d-flex.flex-column.justify-center.px-3
          div.text-body-1.font-weight-medium.pb-1.pt-3 {{ currentItem.product.name }}
          div.text-body-1.font-weight-regular(@click="showAddToCart=true") {{ formatPrice(currentItem.product.price) }}

      ProductBuilder.bg-neutral-a.mt-1(:isCartModifier="true")

      div.float-btn__container
        div.float-btn__background.rounded.px-7.pb-5.pt-2.d-flex.flex-column
          div.text-h6.font-weight-regular.ms-auto.mb-2 {{ $t("general.total") + " " + formatPrice(configurationTotal) }}
          div.float-btn__controls
            EatStepper(@increase="changeConfigurationQuantity(+1)" :value="currentItem.quantity ? currentItem.quantity : 0" @decrease="changeConfigurationQuantity(-1)" :contractable="false")
            EatFloatBtn(@click="onProductBuilderSubmit") 
              div.text-truncate {{ $t("cart.saveChanges") }}                    
</template>

<script setup lang="ts">
import type { CartItem } from "@/models/Cart";
import { useApp } from "@/store/app";
import ItemConfigurationSummary from "@/components/ItemConfigurationSummary.vue";
import ProductBuilder from "@/components/menuProduct/ProductBuilder.vue";
import FullPageDialog from "@/layout/partials/FullPageDialog.vue";
import ItemPrice from "@/components/utils/ItemPrice.vue";
import Price from "@/components/utils/Price.vue";
import { formatPrice } from "@/utils/formatPrice";
import { computed, onBeforeUnmount, ref } from "vue";
import { computePrice, isValid, type Product } from "@/models/Product";
import { useCart } from "@/store/cart";
import { useCartItems } from "@/store/cart/cartItems";
import { isEqualObject } from "@/utils";
import useProductDetail from "@/store/order/productDetail";
import { setSnackbar, TypeSnackbar } from "@/store/layout/snackbar";
import { useI18n } from "vue-i18n";

const i18n = useI18n();
const { location } = useApp();
const { cart } = useCart();
const { removeFromCart, updateQuantity, updateCartItems } = useCartItems();
const showDialog = ref(false);
const showAddToCart = ref(false);
const currentItem = ref<CartItem>();
const currentItemIndex = ref<number>();

const changeQuantity = (item: CartItem, amount: number) => {
  const quantity = item.quantity + amount;
  if (amount < 0) {
    updateQuantity(item, quantity);
  } else if (item.product.warehouseItem) {
    if (item.product.warehouseItem.availableStockQuantity >= quantity) {
      updateQuantity(item, quantity);
    } else {
      setSnackbar(
        i18n.t("order.product.maxAvailableStockQuantityReached").toString(),
        TypeSnackbar.WARNING
      );
    }
  } else {
    updateQuantity(item, quantity);
  }
};

const deleteItem = (item: CartItem) => {
  removeFromCart(item);
};

onBeforeUnmount(() => {
  cart.value?.items?.forEach(item => {
    if (item.quantity === 0) {
      deleteItem(item);
    }
  });
});

const { setConfigurationFromCartItem, reset } = useProductDetail();
const openDetailProductDialog = (item: CartItem, index: number) => {
  currentItemIndex.value = index;
  currentItem.value = { ...item };
  setConfigurationFromCartItem(currentItem.value);
  showDialog.value = true;
};

const onCloseDialog = () => {
  showDialog.value = false;
  currentItem.value = undefined;
  currentItemIndex.value = undefined;
  reset();
};

const getConfigurationTotal = (item: CartItem) => {
  return item.quantity * computePrice(item.product, item.configuration);
};

const configurationTotal = computed(() => {
  if (!currentItem.value?.configuration) return 0;
  return getConfigurationTotal(currentItem.value);
});

const changeConfigurationQuantity = (amount: number) => {
  if (!currentItem.value) return;
  const quantity = currentItem.value.quantity + amount;
  if (amount < 0) {
    currentItem.value.quantity = quantity;
  } else if (currentItem.value.product.warehouseItem) {
    if (currentItem.value.product.warehouseItem.availableStockQuantity >= quantity) {
      currentItem.value.quantity = quantity;
    } else {
      setSnackbar(
        i18n.t("order.product.maxAvailableStockQuantityReached").toString(),
        TypeSnackbar.WARNING
      );
    }
  } else {
    currentItem.value.quantity = quantity;
  }
};

const getEquatableConfiguration = (configuration: any) => {
  return {
    addedComponents: configuration.addedComponents,
    removedComponents: configuration.removedComponents,
    articleAttributes: configuration.articleAttributes,
    modules: configuration.modules,
    mandatoryComponents: configuration.mandatoryComponents,
    upsellingModules: configuration.upsellingModules
  };
};

const getSameConfigurationCartItemsIndexes = (item: CartItem) => {
  const indexes = cart.value?.items.reduce((indexes: number[], cartItem, i) => {
    if (
      isEqualObject(item.product, cartItem.product) &&
      isEqualObject(
        getEquatableConfiguration(item.configuration),
        getEquatableConfiguration(cartItem.configuration)
      ) &&
      i !== currentItemIndex.value
    ) {
      indexes.push(i);
    }
    return indexes;
  }, []);
  return indexes || [];
};

const onProductBuilderSubmit = () => {
  if (
    cart.value &&
    currentItemIndex.value !== undefined &&
    currentItem.value &&
    isValid(currentItem.value.configuration, currentItem.value.product)
  ) {
    const localItems = [...cart.value.items];
    // Se dopo la modifica del singolo prodotto ci sono altri prodotti nel carrello che hanno la stessa configurazione
    const sameConfigurationItemsIndexes = getSameConfigurationCartItemsIndexes(
      currentItem.value
    );
    // Aggiungo le quantità degli altri prodotti al corrente item
    sameConfigurationItemsIndexes.forEach(index => {
      if (currentItem.value) currentItem.value.quantity += localItems[index].quantity || 0;
    });
    // Aggiungo l'item all'array locale di cartItems
    localItems[currentItemIndex.value] = currentItem.value;
    // Rimuovo i prodotti duplicati dall'array locale di cartItems
    sameConfigurationItemsIndexes.forEach((item, index) => {
      localItems.splice(index, 1);
    });
    // Aggiorno gli items nel carrello
    updateCartItems(localItems);
    onCloseDialog();
  }
};

const getImageSrc = (product: Product) => {
  return product && product.images.length ? product.images[0] : location.value?.logoUrl || "";
};
</script>

<style lang="scss" scoped>
$image-height: 76px;
.card {
  display: grid;
  grid-template-columns: $image-height auto 76px;
  align-items: center;
  .card__image {
    width: $image-height;
    height: $image-height;
    object-fit: cover;
  }
  .card__controls {
    text-align: end;
  }
}

.components__container {
  flex-wrap: wrap;
  gap: 4px;
  .outlined--cart-chips {
    border: 1px solid var(--border-clr--type-a);
    color: var(--border-clr--type-a);
  }
}

.product-builder__container {
  padding-bottom: 126px;

  .product-builder__image {
    width: 100%;
  }

  .float-btn__container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    .float-btn__background {
      width: 100%;
      backdrop-filter: blur(2px);
      .float-btn__controls {
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 8px;
      }
    }
  }
}
</style>
