import type { Category } from "@/models";
import { computed } from "vue";
import { useApp } from "../app";
import { useCart } from "../cart";
import { useMenu } from "../menu";

export const useCrossSelling = () => {
  const { cart } = useCart();
  const { app } = useApp();
  const { categories, fetchMenu } = useMenu(cart.value?.saletype);
  fetchMenu();

  const crossSellingCategories = computed(() => {
    if (!cart.value || !app.value) return [] as Category[];
    const categoriesIds =
      app.value.services[cart.value.saletype].crossSelling?.categories || ([] as string[]);
    return categories.value.filter(category => categoriesIds.includes(category.id));
  });

  return {
    crossSellingCategories
  };
};
