<template lang="pug">
EatView(v-if="crossSellingCategories" :loading="!crossSellingCategories.length")
  div.bg-neutral-a.products__container.flex-grow-1.d-flex.flex-column.pb-1(v-scrollable="true")
      TransitionGroup(name="fade" tag="div" v-if="crossSellingCategories && crossSellingCategories.length")
        div.flex-grow-1.d-flex.flex-column(v-for="category, k in crossSellingCategories" :key="k")
          RoundedContainer.bg-white.mt-1.pt-8(
            :id="category.id"
            :horizontal-padding="false"
            :stickyTitle="true"
          )
            h2.pb-3.px-5 {{ category.name }}
            ProductCard(v-for="product, i in category.products" :key="i" :product="product" :class="{'border-t': i > 0}")

      Transition(name="fade" tag="div").flex-grow-1.d-flex.flex-column
        div.products__placeholder.bg-white.rounded-b-xl.flex-grow-1.mb-8.pt-8.d-flex.justify-center(v-if="!crossSellingCategories || !crossSellingCategories.length") 
          div {{ $t("crossSelling.noProducts") }}

  ProductDetail
</template>

<script setup lang="ts">
import ProductCard from "@/components/menuProduct/ProductCard.vue";
import ProductDetail from "@/components/menuProduct/ProductDetail.vue";
import { useCrossSelling } from "@/store/cart/crossSelling";
import { computed, watch } from "vue";
import { nItems } from "@/models/Cart";
import { useCart } from "@/store/cart";
import { setAppTitle } from "@/store/appTitle";
import { useI18n } from "vue-i18n";

const { cart } = useCart();
const { crossSellingCategories } = useCrossSelling();
const i18n = useI18n();

const itemsInCart = computed(() => {
  return cart.value ? nItems(cart.value) : 0;
});
watch(itemsInCart, (value: number) => {
  setAppTitle(i18n.t("cart.title", { items: value }).toString());
}, { immediate: true });
</script>

<style lang="scss" scoped>
.products__container {
  height: 100%;
}
</style>
